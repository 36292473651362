<template>
  <div class="shpage">
    <va-card titleOnImage :image="img" title="东方明珠电视塔"> </va-card>
  </div>
</template>

<script>
// import axios from 'axios';
export default {
  data() {
    return {
      img: require("../../../../../background/1.jpg"),
    };
  },
  // methods:{
  // <va-button small @click="printRceipt">打印</va-button>
  // printRceipt(){
  //     console.log("now start");
  //     const instance1 =  axios.create({
  //         baseURL: 'http://127.0.0.1:8800',
  //         timeout: 5000
  //     });
  //     instance1({
  //         url: '/print',
  //         method: 'post',
  //     }).then(res => {
  //         console.log(res);
  //     });
  // }
  // },
};
</script>
